import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Blog', href: 'https://blog.combatwarriors.gg' },
    { name: 'Wiki', href: 'https://wiki.combatwarriors.gg' },
    { name: "Clans", href: "/clan" },
    { name: 'Codes', href: '/codes' },
    { name: 'Verify', href: '/verify' },
]

export default function NavBar() {
    const router = useRouter();

    const [opacity, setOpacity] = useState(0)

    const [session, setSession] = useState(null);
    const [staff, setStaff] = useState(false);

    useEffect(() => {
        fetch("/api/auth/auth").then((res) => res.json()).then((data) => {
            if (data.success) {
                setSession(data.session);
                setStaff(data?.mod || data?.admin || false)
            }
        });

        window.addEventListener('scroll', () => {
            if (window.scrollY > 50) {
                setOpacity(50)
            }
            else {
                setOpacity(0)
            }
        })
    }, [])

    return (
        <Disclosure as="nav" className={`${opacity >= 50 ? `bg-black` : `` } hover:bg-black bg-opacity-50 hover:bg-opacity-50 transition duration-250 ease-in-out hover:backdrop-blur`} style={{ backdropFilter: opacity >= 50 ? `blur(8px)` : ``, zIndex: 1000 }}>
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                ) : (
                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        className="block h-8 w-auto cursor-pointer"
                                        src="../../../../../../cw_icon.png"
                                        alt="Combat Warriors Icon"
                                        onClick={() => router.push('/')}
                                    />
                                </div>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                onClick={() => router.push(item.href)}
                                                className={'transition text-gray-300 hover:bg-opacity-20 hover:bg-white hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer'}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Profile dropdown */}
                                {session ? (
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                <span className="sr-only">Open user menu</span>
                                                <img
                                                    className="h-8 w-8 rounded-full"
                                                    src={session.user.image}
                                                    alt=""
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-[#121212] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item onClick={() => router.push('/profile')}>
                                                    {({ active }) => (
                                                        <p
                                                            className={'block px-4 py-2 text-sm text-gray-200 bg-[#121212] hover:bg-[#2b2b2b] transition cursor-pointer'}
                                                        >
                                                            Your Profile
                                                        </p>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item onClick={() => router.push('/clan/add')}>
                                                    {({ active }) => (
                                                        <p
                                                            className={'block px-4 py-2 text-sm text-gray-200 bg-[#121212] hover:bg-[#2b2b2b] transition cursor-pointer'}
                                                        >
                                                            Add Clan
                                                        </p>
                                                    )}
                                                </Menu.Item>
                                                {staff ? (
                                                    <Menu.Item onClick={() => router.push('/staff')}>
                                                        {({ active }) => (
                                                            <p
                                                                className={'block px-4 py-2 text-sm text-gray-200 bg-[#121212] hover:bg-[#2b2b2b] transition cursor-pointer'}
                                                            >
                                                                Staff Panel
                                                            </p>
                                                        )}
                                                    </Menu.Item>
                                                ) : null}
                                                <Menu.Item onClick={() => router.push('/api/auth/logout')}>
                                                    {({ active }) => (
                                                        <a
                                                            className={'block px-4 py-2 text-sm text-gray-200 bg-[#121212] hover:bg-[#2b2b2b] transition cursor-pointer'}
                                                        >
                                                            Sign out
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                ) : (
                                    <button
                                        onClick={() => router.push('/api/auth')}
                                        className="text-white px-4 py-2 rounded-md text-sm font-medium bg-indigo-600 hover:bg-indigo-700 transition"
                                    >
                                        Sign In
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden bg-black">
                        <div className="space-y-1 px-2 pb-3 pt-2 bg-black">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    onClick={() => router.push(item.href)}
                                    className={'cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium'}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
